import { Category, CategoryNames, Subcategory, SubcatType } from '@omniafishing/core';
import _ from 'lodash';
import { RoutePaths } from './routes';

export const SUBCATEGORY_GIFTCARDS = 'accessories-giftcard';

export function getCategoryByName(categories: Category[], name: CategoryNames) {
  return _.find(categories, { name });
}

export function getCategoryByProductType(categories: Category[], productType: string) {
  const productTypeCategoryName = productType.split('-')[0] as CategoryNames;
  return getCategoryByName(categories, productTypeCategoryName);
}

export function getCategoryBySubcategoryUrl(categories: Category[], subcategoryUrlPath: string) {
  return _.find(categories, (category) =>
    _.some(
      category.subcategories,
      (subcategory: Subcategory) => subcategory.url_path === subcategoryUrlPath
    )
  );
}

export function variantProductTypeToSubcategoryName(productType: string) {
  const productTypeSplit = productType.split('-');
  return `${productTypeSplit[0]}-${productTypeSplit[1]}`;
}

export function getSubcategoryByProductType(categories: Category[], productType: string) {
  const category = getCategoryByProductType(categories, productType);
  if (category == null) {
    return undefined;
  }
  const productTypeSubcategory = variantProductTypeToSubcategoryName(productType);
  return _.find(
    category.subcategories,
    (subcategory) => subcategory.name === productTypeSubcategory
  );
}

export function getSubcategoryByUrlPath(subcategories: Subcategory[], subcategoryUrlPath: string) {
  return _.find(subcategories, (subcategory) => subcategory.url_path === subcategoryUrlPath);
}

export function getSubcategoryByName(subcategories: Subcategory[], subcategoryName: string) {
  return _.find(subcategories, (subcategory) => subcategory.name === subcategoryName);
}

export function getSubcatTypeBySlug(subcatTypes: SubcatType[], slug: string) {
  return _.find(subcatTypes, (s) => s.slug === slug);
}

export enum NavigationGroupNames {
  BAIT = 'Baits',
  LINE = 'Line',
  ACCESSORIES = 'Accessories',
  TERMINAL = 'Terminal',
  RODS = 'Rods',
  REELS = 'Reels',
  APPAREL = 'Apparel',
  MARINE = 'Marine',
  DEALS = 'Deals',
  PRO = 'What is PRO?',
}

export type NavigationGroupLowercase = Lowercase<`${NavigationGroupNames}`>;

interface NavigationGroup {
  name: NavigationGroupNames;
  categories: Category[];
  sidebar?: {
    title: string;
    items: {
      title: string;
      url: string;
    }[];
  };
  url?: string;
}

export function getNavigationGroups(categories: Category[]): NavigationGroup[] {
  return [
    {
      name: NavigationGroupNames.BAIT,
      categories: [
        getCategoryByName(categories, 'jigs'),
        getCategoryByName(categories, 'softbaits'),
        getCategoryByName(categories, 'hardbaits'),
        getCategoryByName(categories, 'spinnerbaits'),
        getCategoryByName(categories, 'rigs'),
      ].filter(Boolean),
    },
    {
      name: NavigationGroupNames.LINE,
      categories: [getCategoryByName(categories, 'lines')].filter(Boolean),
    },
    {
      name: NavigationGroupNames.RODS,
      categories: [getCategoryByName(categories, 'rods')].filter(Boolean),
    },
    {
      name: NavigationGroupNames.REELS,
      categories: [getCategoryByName(categories, 'reels')].filter(Boolean),
    },
    {
      name: NavigationGroupNames.TERMINAL,
      categories: [getCategoryByName(categories, 'terminal')].filter(Boolean),
    },
    {
      name: NavigationGroupNames.ACCESSORIES,
      categories: [getCategoryByName(categories, 'accessories')].filter(Boolean),
    },
    {
      name: NavigationGroupNames.APPAREL,
      categories: [],
      url: `${RoutePaths.CATEGORIES}/${getCategoryByName(categories, 'apparel')?.url_path}`,
    },
    {
      name: NavigationGroupNames.MARINE,
      categories: [],
      url: `${RoutePaths.CATEGORIES}/${getCategoryByName(categories, 'marine')?.url_path}`,
    },
    {
      name: NavigationGroupNames.PRO,
      categories: [],
      url: `${RoutePaths.PREMIUM_PRO}`,
    },
    {
      name: NavigationGroupNames.DEALS,
      categories: [],
      url: `${RoutePaths.DEALS}`,
    },
  ];
}

export const getShopByCategoryCategories = (categories: Category[]): NavigationGroup[] => {
  // Filter out ice fishing subcategories from the accessories category
  const accessoryCategory = _.cloneDeep(getCategoryByName(categories, 'accessories'));
  accessoryCategory.subcategories = accessoryCategory.subcategories.filter(
    (subcategory) => !subcategory.name.includes('ice')
  );

  return [
    {
      name: NavigationGroupNames.BAIT,
      categories: [
        getCategoryByName(categories, 'jigs'),
        getCategoryByName(categories, 'softbaits'),
        getCategoryByName(categories, 'hardbaits'),
        getCategoryByName(categories, 'spinnerbaits'),
        getCategoryByName(categories, 'rigs'),
      ].filter(Boolean),
    },
    {
      name: NavigationGroupNames.LINE,
      categories: [getCategoryByName(categories, 'lines')].filter(Boolean),
    },
    {
      name: NavigationGroupNames.RODS,
      categories: [getCategoryByName(categories, 'rods')].filter(Boolean),
    },
    {
      name: NavigationGroupNames.REELS,
      categories: [getCategoryByName(categories, 'reels')].filter(Boolean),
    },
    {
      name: NavigationGroupNames.TERMINAL,
      categories: [getCategoryByName(categories, 'terminal')].filter(Boolean),
    },
    {
      name: NavigationGroupNames.ACCESSORIES,
      categories: [accessoryCategory].filter(Boolean),
    },
    {
      name: NavigationGroupNames.APPAREL,
      categories: [getCategoryByName(categories, 'apparel')].filter(Boolean),
      url: `${RoutePaths.CATEGORIES}/${getCategoryByName(categories, 'apparel')?.url_path}`,
    },
    {
      name: NavigationGroupNames.MARINE,
      categories: [getCategoryByName(categories, 'marine')].filter(Boolean),
      url: `${RoutePaths.CATEGORIES}/${getCategoryByName(categories, 'marine')?.url_path}`,
    },
  ];
};

export function getNavigationGroupByCategory(categories: Category[], category: Category) {
  const navGroups = getNavigationGroups(categories);
  return navGroups.filter((navGroup) =>
    navGroup.categories.some((groupCategory) => groupCategory.name === category.name)
  )[0];
}

export const subcategoriesExcludePickup = [
  'apparel-icefishinggloves',
  'apparel-icefishingjacket',
  'apparel-icefishingbibs',
  'accessories-bags',
  'apparel-kidswaders',
  'apparel-womenswaders',
  'apparel-pants',
  'apparel-layering',
  'apparel-jackets',
  'apparel-jackets',
  'apparel-gloves',
  'apparel-fishingshirt',
  'apparel-wadingboots',
  'apparel-rainwear',
  'apparel-waders',
  'accessories-marine',
  'accessories-iceaccessories',
];
